import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const PostPreview = ({ node }) => (
  <PostPreviewWrapper>
    <h2>
      <Link to={`/blog/${node.slug}`}>{node.frontmatter.title}</Link>
    </h2>
    <p>{node.frontmatter.date}</p>
    <p>{node.frontmatter.spoiler}</p>
  </PostPreviewWrapper>
)

const PostPreviewWrapper = styled.article`
  margin-bottom: 1em;

  a {
    text-decoration: none;
  }

  h2 {
    margin-bottom: 0.3em;
  }

  p {
    margin: 0.25em 0;
  }
`

export default PostPreview
